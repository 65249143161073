.btn {
  &__eye {
    cursor: pointer;
  }
}

.container-login {
  .login-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-image: url('../../../../public/assets/images/login-img.png');
  }

  .login-form {
    -webkit-backdrop-filter: blur(7.5px);
    backdrop-filter: blur(7.5px);
    background: linear-gradient(180deg, hsla(0, 0%, 100%, .3), hsla(0, 0%, 100%, 0));
    border: 2px solid hsla(0, 0%, 100%, .3);
    border-radius: 0.3125rem;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    max-width: 35rem;
    padding: 0 40px;
    margin-top: 6rem;
    margin-bottom: 10rem;
    margin-left: 4rem;
  }

  .form-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 30px 50px;
  }

  .anchor-style {
    text-decoration: none;
  }

  .login-title {
    font-size: 36px;
    font-weight: bold;
  }

  .custom-input {
    background-color: #98A0B4;
    color: white;
    border: none;
    height: 45px;
  }

  .custom-input:focus {
    background-color: #98A0B4;
    color: white;
  }

  @media (max-width: 767px) {
    .login-form {
      max-width: 30rem;
      padding: 0 30px;
      margin-top: 6rem;
      margin-bottom: 10rem;
      margin-left: 2rem;
    }
  }
  @media (max-width: 532px) {
    .login-form {
      max-width: 20rem;
      padding: 0 10px;
      margin-top: 4rem;
      margin-bottom: 6rem;
      margin-left: 1rem;
    }
    .login-title {
      font-size: 22px;
    }
    .new-link {
      font-size: 11px !important;
    }
  }
  @media (max-width: 350px) {
    .login-form {
      max-width: 17rem;
      padding: 0 2px;
      margin-top: 4rem;
      margin-bottom: 4rem;
      margin-left: 1rem;
    }
    .login-title {
      font-size: 17px;
    }
    .new-link {
      font-size: 8px !important;
    }
  }
  @media (max-width: 300px) {
    .login-form {
      max-width: 15rem;
      padding: 0 2px;
      margin-top: 4rem;
      margin-bottom: 4rem;
      margin-left: 1rem;
    }
    .login-title {
      font-size: 15px;
    }
    .new-link {
      font-size: 8px !important;
    }
  }
}