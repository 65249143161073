@use "../../../styles/partials/variables" as *;

.plans {

    border: 2px solid $xequemate-orange;
    border-radius: $radius;
    margin: 1rem;

    &__button {

        margin: .5rem;

        &--selected {
            border: 2px solid $xequemate-white;
        }
    }

    &__id {
        margin-left: .5rem;
        margin-top: .5rem;
    }

    &__article {
        margin: 3rem;
        padding: .5rem;

        & > h3 {
            margin: .5rem;
            color: $xequemate-orange;
        }

        &--diamante {
            border: 2px solid $xequemate-orange;
            border-radius: $radius;
        }
    }

    &__name {
        color: $xequemate-orange;
    }

    &__p {
        font-size: 1rem;
        color: $xequemate-white;
    }

    &__row {
        display: flex;
        flex-direction: column;
    }
}





//.credit-card {
//  select {
//    border: 1px solid #d7d7d7;
//    border-radius: 0;
//    background: transparent;
//    height: 50px;
//    color: #666;
//    padding-left: 15px;
//  }
//}