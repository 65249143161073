@use '../../styles/partials/variables' as *;
@use "../../styles/partials/mixins" as *;

.quantity {

    &__title {
        margin: .5rem 0rem;
        color: $xequemate-orange;
    }
    &__quantity {
        color: $xequemate-black;
        margin-right: .25rem;
    }
}
//
//
//.product-page {
//    padding-block-start: 1rem;
//    padding-inline: 8rem;
//    background-color: $xequemate-blue-dark;
//    &__banner-container {
//        margin-block: 4rem;
//        border-radius: 5px;
//        overflow: hidden;
//    }
//    &__content {
//        display: flex;
//        justify-content: center;
//        gap: 4rem;
//    }
//
//    &__arrow {
//        width: 1.5rem;
//    }
//
//    &__button {
//        @include button-cta-primary;
//        padding-inline-start: 1rem;
//        display: flex;
//        width: fit-content;
//        align-items: center;
//        justify-content: center;
//        margin-bottom: 2rem;
//    }
//    &__link {
//        align-self: flex-start;
//    }
//
//    &__dropdown-menu {
//        background-color: transparent;
//        border: none;
//        margin-inline: 2rem;
//    }
//    &__dropdown-container {
//        position: relative;
//        width: 18.75rem;
//        z-index: 20;
//    }
//}
//
//.product-card {
//    background-color: $xequemate-khaki;
//    width: 80%;
//    padding: 2rem 4rem;
//    display: flex;
//    flex-direction: column;
//    align-self: center;
//    border-radius: $radius;
//    position: relative;
//    z-index: 0;
//    &__img-wrapper {
//        display: flex;
//        justify-content: space-between;
//        margin-block-end: 1rem;
//    }
//    &__img-container {
//        width: 100%;
//        height: 31.25rem;
//        background-color: white;
//        overflow: hidden;
//        max-width: 500px;
//        position: relative;
//    }
//    &__timer-placeholder {
//        position: absolute;
//        top: 0;
//        left: 0;
//        z-index: 1;
//        text-align: center;
//        font-weight: 600;
//        background-color: $xequemate-orange;
//        width: 10rem;
//        height: 1.5rem;
//    }
//    &__main-img {
//        display: block;
//        height: 100%;
//        object-fit: contain;
//        margin: auto;
//    }
//    &__select-img {
//        border: none;
//        padding: 0;
//        width: 11rem;
//        height: 6.75rem;
//        background-color: white;
//        &:hover {
//            outline: 2px solid $xequemate-orange;
//        }
//    }
//    &__small-images {
//        display: flex;
//        flex-direction: column;
//        justify-content: flex-start;
//        align-items: flex-end;
//        gap: 2rem;
//        width: 50%;
//    }
//    &__small-img {
//        height: 100%;
//        display: block;
//        object-fit: contain;
//        margin: auto;
//    }
//    &__name {
//        margin-block-start: 1rem;
//        margin-block-end: 0;
//        font-size: 2rem;
//    }
//    &__quantity {
//        margin-block-end: 2rem;
//    }
//    &__description {
//        width: 75%;
//    }
//    &__summary {
//        align-self: flex-end;
//        display: flex;
//        flex-direction: column;
//        width: 100%;
//        margin-block-end: 1rem;
//    }
//    &__price {
//        font-size: 2rem;
//        text-align: end;
//        margin-block-end: 1rem;
//    }
//    &__buttons {
//        display: flex;
//        justify-content: space-between;
//        align-self: flex-end;
//    }
//    &__button {
//        @include button-cta-primary;
//        width: 12rem;
//        padding-inline: 2rem;
//    }
//    &__quantity-controler {
//        display: flex;
//        align-items: center;
//        gap: 0.75rem;
//    }
//    &__number {
//        width: 2.5rem;
//        height: 2.5rem;
//        text-align: center;
//    }
//    &__unit {
//        margin-inline-end: 2rem;
//    }
//}
//.cart__button--quantity {
//    border-radius: 3px;
//}
//.active {
//    border: 2px solid $xequemate-orange;
//}
//
//.hidden {
//    display: none;
//}