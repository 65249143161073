@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.cart {

    &__p {
        color: $xequemate-orange;
    }

    &__p2 {
        color: $xequemate-black;
        margin-top: .5rem;
    }
}
//
//.cart {
//    width: 100%;
//    height: 100%;
//    background-color: $xequemate-blue-dark;
//    // background-image: url('../../assets/backgroung-img-blue.svg');
//    // background-repeat: no-repeat;
//    // background-position: left 3rem center;
//
//    &__margin {
//        margin: 1rem 0;
//        display:flex;
//        justify-content: space-between;
//    }
//
//    &__title {
//        text-align: left;
//        color: $xequemate-black;
//        margin-bottom: 2rem;
//    }
//
//    &__salePrice {
//        min-width: 140px;
//        text-align: center;
//    }
//
//    &__list {
//        padding-inline-start: 0;
//        &--title {
//            padding: 1rem;
//            width: 100%;
//        }
//    }
//
//    &__section {
//        padding-block-start: 8rem;
//
//        &--card {
//
//            width: 85%;
//            margin-left: 7.5%;
//            height: 100%;
//
//            padding: 2rem 4rem;
//            border-radius: 0.3125rem;
//            background: $xequemate-khaki;
//        }
//    }
//
//    &__item {
//        display: flex;
//        flex-direction: row;
//        align-items: center;
//        justify-content: space-between;
//        width: 100%;
//        height: 7rem;
//        padding-block: 1.5rem;
//        border-bottom: .0625rem solid $xequemate-gray-dark;
//
//        &:hover {
//            background-color: $xequemate-khaki-dark;
//        }
//
//        &--image {
//            width: 101px;
//            height: 62px;
//            margin-right: 1rem;
//        }
//
//        &--title {
//            width: 12rem;
//            display: flex;
//            flex-direction: column;
//        }
//        &--title h3{
//           margin: 0.5rem 0;
//        }
//
//        &--unit {
//            display: flex;
//            align-items: center;
//            justify-content: center;
//        }
//
//        &--number {
//            background-color: $xequemate-white;
//            width: 100px;
//            padding: .5rem 1rem;
//        }
//
//        &--quantity {
//            display: flex;
//            :disabled {
//                background: #e3c4ab !important;
//                opacity: .6;
//            }
//        }
//    }
//    &__delete-icon {
//        margin-inline: 1rem;
//    }
//
//    &__button {
//
//        &--quantity {
//            display: flex;
//            align-items: center;
//            justify-content: center;
//            background-color: $xequemate-orange;
//            border: none;
//            padding: .5rem 1rem;
//
//            &:hover {
//                background-color: $xequemate-orange-dark;
//            }
//        }
//
//        &--text {
//            @include button-cta-primary;
//            margin: 0rem 1.5rem;
//        }
//
//        &--delete {
//            background-color: transparent;
//            border: none;
//        }
//    }
//
//    &__submit {
//        display: flex;
//        flex-direction: column;
//        align-items: flex-start;
//        padding-inline-start: 2.5rem;
//        margin-block: 1rem;
//        border-left: .0625rem solid $xequemate-gray-dark;
//
//        &--title {
//            color: $xequemate-orange-dark;
//            margin-block-start: 0;
//            margin-block-end: 1rem;
//            font-size: 1.25rem;
//        }
//
//        &--price {
//            color: $xequemate-black;
//            font-size: 1.25rem;
//            margin-block-start: 0;
//            margin-block-end: 1rem;
//        }
//
//        &--button {
//            @include button-cta-primary;
//            width: 12rem;
//            margin: 1rem 0rem;
//        }
//    }
//    &__price-wrapper {
//        width: 100%;
//        display: flex;
//        justify-content: space-between;
//    }
//}