.RegisterPage {
  .register-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-image: url('../../../../public/assets/images/register.png');
  }

  .register-form {
    -webkit-backdrop-filter: blur(7.5px);
    backdrop-filter: blur(7.5px);
    background: linear-gradient(180deg, hsla(0, 0%, 100%, .3), hsla(0, 0%, 100%, 0));
    border: 2px solid hsla(0, 0%, 100%, .3);
    border-radius: 0.3125rem;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    max-width: 45rem;
    padding: 0 40px;
    margin-top: 6rem;
    margin-bottom: 10rem;
    margin-left: 4rem;
  }

  @media (max-width: 991px) {
    .register-form {
      max-width: 40rem;
      padding: 0 40px;
      margin-top: 6rem;
      margin-bottom: 8rem;
      margin-left: 2rem;
    }
  }
  @media (max-width: 767px) {
    .register-form {
      max-width: 40rem;
      padding: 0 40px;
      margin-top: 6rem;
      margin-bottom: 8rem;
      margin-left: 1rem;
    }
    .criar-btn {
      margin-top: 25px;
    }
  }
  @media (max-width: 640px) {
    .register-form {
      max-width: 30rem;
      padding: 0 30px;
      margin-top: 6rem;
      margin-bottom: 8rem;
      margin-left: 1rem;
    }
  }
  @media (max-width: 530px) {
    .register-form {
      max-width: 25rem;
      padding: 0 20px;
      margin-top: 3rem;
      margin-bottom: 4rem;
      margin-left: 1rem;
    }
  }
  @media (max-width: 445px) {
    .register-form {
      max-width: 20rem;
      padding: 0 10px;
      margin-top: 3rem;
      margin-bottom: 4rem;
      margin-left: 1rem;
    }
    .register-title {
      font-size: 30px !important;
      font-weight: bold;
    }
    .register-subtitle {
      font-size: 15px !important;
    }
    .register-para {
      font-size: 14px;
    }
    .radio-text {
      font-size: 16px !important;
    }
  }
  @media (max-width: 351px) {
    .register-form {
      max-width: 18rem;
      padding: 0 1px;
      margin-top: 3rem;
      margin-bottom: 4rem;
      margin-left: 1rem;
    }
  }
  @media (max-width: 313px) {
    .register-form {
      max-width: 15rem;
      padding: 0 1px;
    }
    .register-title {
      font-size: 22px !important;
      font-weight: bold;
    }
    .register-subtitle {
      font-size: 14px !important;
    }
    .register-para {
      font-size: 12px;
    }
    .radio-text {
      font-size: 13px !important;
    }
  }

  .form-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 30px 50px;
  }

  .anchor-style {
    text-decoration: none;
  }

  .register-title {
    font-size: 36px;
    font-weight: bold;
  }

  .radio-group {
    display: flex;
    flex-direction: column;
  }

  .radio-label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .radio-label input[type="radio"] {
    margin-right: 10px;
    transform: scale(2);
  }

  .radio-text-color {
    color: var(--orangeColor);
  }


  .custom-input {
    background-color: #98A0B4;
    color: white;
    border: none;
    height: 45px;
  }

  .custom-input:focus {
    background-color: #98A0B4;
    color: white;
  }
}