@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,700&display=swap');


:root {
  --whiteColor: #ffffff;
  --blackColor: #000000;
  --lightGrayColor: rgba(0, 0, 0, 0.70);
  --orangeColor: #F26600;
  --borderColor: rgba(0, 0, 0, .2);
  --fontLight: 300;
  --fontRegular: 400;
  --fontMedium: 500;
  --fontSemiBold: 600;
  --fontBold: 700;
  --fontExtraBold: 800;
  --fontBlack: 900;
  --fontPrices: 'Lato', sans-serif;
}


body {
  background: linear-gradient(224deg, #0F214F 0%, #081024 100%);
  color: var(--whiteColor);
  font-family: 'Roboto', sans-serif;
  font-weight: var(--fontRegular);
}

* h1, h2, h3, h4, h5, h6, ul, ol, p {
  margin: 0;
  padding: 0;
}

.noplan {
  padding: 5rem;
  background-color: #ffffff;

  &__button {
    border-radius: .5rem;
    border: none;
    background-color: #F26600;
    color: #FFF;
    margin: 1rem;
  }
}

.noplan > * {
  color: #F26600;
}

.ProductsPage, .ProductPage, .MyCart, .MyProfile {
  * {
    scrollbar-width: thin;
    scrollbar-color: #FFF orange;
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    background: orange;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #FFF;
    border-radius: 20px;
    border: 3px solid orange;
  }
  .container {
    max-width: 1050px;
  }

  .btn-primary {
    background-color: #0F214F;
    border: 2.5px solid var(--orangeColor);
    color: var(--orangeColor) !important;
    padding: 7px 25px;
    font-weight: var(--fontSemiBold);
  }

  .btn-primary:hover {
    background-color: var(--orangeColor);
    color: white !important;
    border: 2.5px solid var(--orangeColor);
  }

  .btn-secondary {
    background-color: var(--orangeColor);
    border: 2px solid var(--orangeColor);
    color: #0F214F !important;
    padding: 7px 25px;
    font-weight: var(--fontSemiBold);
  }

  .btn-secondary:hover {
    border: 2px solid var(--orangeColor);
    background-color: var(--orangeColor);
    color: white !important;
  }

  h2 {
    font-size: 24px;
    font-weight: var(--fontBold);
    color: var(--blackColor);
  }

  .modal-content {
    border-radius: 5px;
    background: #F3CFB5;
  }

  .modal-header {
    border-bottom: 0;
  }

  .modal-header .btn-close {
    opacity: 1;
    font-size: 20px;
  }

  .modal-body {
    text-align: center;
    padding-bottom: 60px;
  }

  .modal-title {
    text-align: center;
    color: var(--blackColor);
    font-size: 32px;
    font-weight: var(--fontBold);
  }

  .modal-body p {
    font-size: 24px;
    font-weight: var(--fontMedium);
    color: var(--blackColor);
  }

  .modal-body p span {
    font-size: 24px;
    font-weight: var(--fontBold);
    color: var(--orangeColor);
  }


  .navbar {
    background-color: #0F214F !important;
  }

  nav ul li a, nav a {
    color: #FFF !important;
  }

  .nav-link {
    font-size: 18px;
    font-weight: var(--fontMedium);
    text-transform: uppercase;
  }

  nav ul li a:hover, nav a:hover {
    color: var(--orangeColor) !important;
  }


  .product-page-wrapper {
    padding: 40px 0 115px;
    min-height: calc(100vh - 90px);
  }

  .product-page-wrapper::before {
    content: '';
    position: fixed;
    bottom: 0;
    left: 50px;
    width: 450px;
    height: 646px;
    background-image: url('./../../../public/assets/images/background-shape.png');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }

  .breadcrumb-main-area {
    margin-bottom: 40px;
  }

  .breadcrumb-left .breadcrumb {
    margin: 0;
  }

  .breadcrumb-left .breadcrumb li {
    color: var(--whiteColor);
    font-size: 14px;
    font-weight: var(--fontMedium);
  }

  .breadcrumb-item + .breadcrumb-item {
    padding-left: 14px;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    content: '\f105';
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    color: var(--whiteColor);
    padding-right: 14px;
  }

  .breadcrumb-left .breadcrumb li a {
    color: var(--whiteColor);
    font-size: 16px;
    font-weight: var(--fontMedium);
    text-decoration: none;
  }

  .breadcrumb-left .breadcrumb li a:hover {
    color: var(--orangeColor);
  }

  .filters-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .filters-right .search-main .form-control {
    width: 210px;
    height: 40px;
    background-color: #a8a8a8;
    border: 0;
    color: var(--blackColor);
    font-size: 16px;
    font-weight: var(--fontBold);
    background-image: url('./../../../public/assets/images/search.png');
    background-repeat: no-repeat;
    background-position: 9px center;
    background-size: 25px;
    padding-left: 45px;
  }

  .grid-button {
    cursor: pointer;
  }

  .filter-main {
    margin: 0 25px;
    cursor: pointer;
  }

  .filter-main svg {
    margin-right: 6px;
  }


  .promo-banner-main {
    border-radius: 5px;
    border-right: 2px solid var(--orangeColor);
    border-bottom: 2px solid var(--orangeColor);
    border-left: 2px solid var(--orangeColor);
    margin-bottom: 35px;
  }

  .promo-banner-image img {
    width: 100%;
  }

  .promo-products-main {
    padding: 30px 80px;
  }

  .promo-products-main .owl-nav button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .promo-products-main .owl-nav button.owl-next {
    right: -50px;
    font-size: 30px;
  }

  .promo-products-main .owl-nav button.owl-prev {
    left: -50px;
    font-size: 30px;
  }

  .promo-products-main .owl-nav button:hover {
    color: var(--orangeColor);
  }


  .product-box {
    background-color: #F3CFB5;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin-bottom: 25px;
  }

  .product-image {
    box-shadow: 0px 2.159291982650757px 10.796460151672363px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    cursor: pointer;
  }

  .product-image img {
    height: 139px;
    width: 100%;
  }

  .product-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px;
  }

  .price-section {
    font-family: var(--fontPrices);
  }

  .product-content .product-content-left a {
    color: var(--blackColor);
    font-size: 18px;
    font-weight: var(--fontSemiBold);
    text-decoration: none;
  }

  .product-content .product-content-left p {
    color: var(--lightGrayColor);
    font-size: 16px;
    font-weight: var(--fontMedium);
  }

  .product-content .product-content-right {
    text-align: right;
  }

  .product-content .product-content-right h3 {
    color: var(--blackColor);
    font-size: 20px;
    font-weight: var(--fontBold);
    margin-bottom: 14px;
  }

  .product-content .product-content-right button {
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
  }

  .product-content .product-content-right button span {
    display: none;
  }

  .product-content .product-content-right button svg {
    font-size: 20px;
    color: var(--orangeColor);
  }

  .promo-product-box .product-content .product-content-right h3 {
    color: var(--orangeColor);
  }

  .product-time-date {
    //position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: var(--orangeColor);
    text-align: center;
    padding: 5px;
  }

  .product-time-date h3 {
    color: var(--blackColor);
    font-size: 16px;
    font-weight: var(--fontBold);
  }

  .product-list-view {
    display: flex;
    align-items: center;
  }

  .product-list-view .product-content {
    display: unset;
    padding: 10px 25px;
    width: 70%;
  }

  .product-list-view .product-content .product-content-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-top: 17px;
  }

  .product-list-view .product-content .product-content-right h3 {
    margin-bottom: 0;
  }

  .product-list-view .product-content .product-content-right button span {
    display: block;
    color: var(--blackColor);
    font-size: 18px;
    font-weight: var(--fontSemiBold);
    margin-left: 14px;
  }

  .product-list-view .product-content .product-content-right button:hover span {
    color: var(--orangeColor);
  }


  .category-left-menu {
    -webkit-backdrop-filter: blur(7.5px);
    backdrop-filter: blur(7.5px);
    background: linear-gradient(180deg, hsla(0, 0%, 100%, .2), hsla(0, 0%, 100%, 0));
    border: 2px solid hsla(0, 0%, 100%, .3);
    border-radius: .5rem;
    overflow: hidden;
    min-height: 80vh;
    max-height: 80vh;
    overflow-y: auto;
  }

  .category-left-menu ul li a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 20px;
    text-decoration: none;
    font-weight: var(--fontSemiBold);
    color: var(--whiteColor);
    transition: all 0.5s ease;
    border-bottom: solid 1px var(--borderColor);
  }

  .category-left-menu ul li a:hover {
    background-color: var(--orangeColor);
    color: var(--blackColor);
    font-weight: var(--fontSemiBold);
  }

  .category-left-menu ul li.active a {
    background-color: var(--orangeColor);
    color: var(--blackColor);
    font-weight: var(--fontSemiBold);
  }


  .category-slider-main {
    padding: 0 60px;
    margin-bottom: 40px;
  }

  .category-box {
    -webkit-backdrop-filter: blur(7.5px);
    backdrop-filter: blur(7.5px);
    background: linear-gradient(180deg, hsla(0, 0%, 100%, .2), hsla(0, 0%, 100%, 0));
    border: 1px solid hsla(0, 0%, 100%, .3);
    border-radius: .5rem;
    overflow: hidden;
    text-align: center;
    padding: 7px 5px;
    min-height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .category-box a {
    text-decoration: none;
  }

  .category-box p {
    color: var(--whiteColor);
    font-weight: var(--fontMedium);
    font-size: 14px;
    text-decoration: none;
    margin-top: 4px;
  }

  .category-box:hover p {
    color: var(--orangeColor);
  }

  .category-slider-main .owl-nav button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .category-slider-main .owl-nav button.owl-next {
    right: -30px;
    font-size: 30px;
  }

  .category-slider-main .owl-nav button.owl-prev {
    left: -30px;
    font-size: 30px;
  }

  .category-slider-main .owl-nav button:hover {
    color: var(--orangeColor);
  }

  .partners-slider-main h3 {
    color: var(--whiteColor);
    font-size: 20px;
    font-weight: var(--fontMedium);
    margin-bottom: 20px;
  }


  .product-detail-area {
    border-radius: 5px;
    background: #F3CFB5;
    padding: 60px 40px 40px;
  }

  .product-view {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .product-view .product-view-image {
    flex: 0 0 80%;
    padding-right: 50px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
  }

  .product-view .product-view-image img {
    //width: 100%;
    //497 × 306 px
    width: 497px;
    height: 306px;
    border-radius: 10px;
  }

  .product-view .product-view-image .product-time-date {
    width: 50%;
  }

  .product-view .product-image-list {
    flex: 0 0 20%;
  }

  .product-view .product-image-list ul {
    list-style: none;
    padding: 0;
    max-height: 300px;
    overflow-y: auto;
  }

  .product-view .product-image-list ul li {
    margin-bottom: 10px;
    border-radius: 10px;
  }

  .product-view .product-image-list ul li.active {
    border: solid 2px var(--orangeColor);
  }

  .product-view .product-image-list ul li img {
    width: 100%;
    border-radius: 10px;
    max-height: 122px;
  }

  .product-information {
    margin: 18px 0 30px;
  }

  .product-title {
    margin: 10px 0;
  }

  .product-title h1 {
    color: var(--blackColor);
    font-weight: var(--fontSemiBold);
    font-size: 24px;
  }

  .product-title p {
    color: var(--lightGrayColor);
    font-weight: var(--fontMedium);
    font-size: 16px;
  }

  .product-description {
    width: 73%;
    color: var(--blackColor);
  }

  .product-description p {
    font-weight: var(--fontMedium);
    font-size: 16px;
  }

  .price {
    color: var(--blackColor);
    font-weight: var(--fontBold);
    font-size: 32px;
    text-align: right;
  }

  .product-form-main {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .quantity-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .quantity-box input {
    width: 100px;
    height: 40px;
    border: 0;
    background-color: var(--whiteColor);
    text-align: center;
  }

  .quantity-box button {
    width: 40px;
    height: 40px;
    border: 0;
    background-color: var(--orangeColor);
    text-align: center;
    color: var(--blackColor);
    font-weight: var(--fontBold);
    font-size: 35px;
    line-height: 0;
  }

  .product-form-main .addtocart-button {
    margin-left: 20px;
  }


  .cart-main-area {
    border-radius: 5px;
    background: #F3CFB5;
    padding: 36px 40px 70px;
  }

  .cart-item-area {
    border-right: solid 1px #4A4A4A;
  }

  .cart-items-main {
    margin-top: 40px;
    padding-right: 40px;
  }

  .cart-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px #4A4A4A;
    margin-bottom: 5px;
  }

  .cart-image {
    width: 100px;
  }

  .cart-image img {
    width: 100%;
  }

  .cart-title {
    width: 35%;
    font-size: 18px;
    color: var(--blackColor);
    font-weight: var(--fontSemiBold);
  }

  .cart-price {
    font-size: 18px;
    color: var(--blackColor);
    font-weight: var(--fontBold);
  }

  .cart-delete-btn button {
    background-color: transparent;
    border: 0;
    font-size: 25px;
    color: #4A4A4A;
  }

  .cart-delete-btn button:hover {
    color: var(--orangeColor);
  }

  .cart-summary-area {
    padding-left: 20px;
  }

  .cart-summary-main {
    margin-top: 40px;
  }

  .cart-summary-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
  }

  .cart-summary-item p {
    font-size: 18px;
    color: var(--blackColor);
    font-weight: var(--fontBold);
  }

  .total-amount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: solid 1px #4A4A4A;
    margin: 18px 0;
    padding-top: 18px;
  }

  .order-button {
    text-align: center;
    margin-top: 50px;
  }


  .profile-card {
    border-radius: 5px;
    border: 2px solid rgba(255, 255, 255, 0.30);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
    backdrop-filter: blur(7.5px);
    padding: 10px 12px 12px;
  }

  .profile-title {
    border-radius: 5px;
    border: 2px solid rgba(255, 255, 255, 0.30);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
    backdrop-filter: blur(7.5px);
    text-align: center;
    padding: 10px 12px 12px;
  }

  .profile-title h1 {
    font-size: 24px;
    color: var(--whiteColor);
    font-weight: var(--fontBold);
  }

  .plan-main-area {
    padding: 20px 30px;
  }

  .plan-main-area h2 {
    color: var(--whiteColor);
    font-size: 20px;
  }

  .plan-main-area p {
    font-size: 16px;
    font-weight: var(--fontMedium);
    font-size: 18px;
  }

  .profile-information-main h3 {
    color: var(--whiteColor);
    font-size: 22px;
    font-weight: var(--fontBold);
  }

  .profile-information-main h4 {
    color: var(--whiteColor);
    font-size: 18px;
    font-weight: var(--fontBold);
    margin-bottom: 6px;
  }

  .profile-information-main p {
    color: var(--whiteColor);
    font-size: 15px;
    font-weight: var(--fontMedium);
  }

  .order-main-area {
    overflow-x: auto;
  }

  .order-main-area h2 {
    color: var(--whiteColor);
  }

  .order-main-area .table {
    width: 970px;
  }

  .table {
    color: var(--whiteColor);
  }

  .table-striped > tbody > tr:nth-of-type(2n+1) {
    color: var(--bs-table-striped-color);
    background: rgba(255, 255, 255, 0.50);
  }

  .table-hover > tbody > tr:hover {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--whiteColor);
  }


  footer {
    background-color: #0F214F !important;
  }

  .footer-container {
    padding-top: 80px;
    padding-bottom: 20px;
  }

  .custom-links li {
    list-style: none;
    line-height: 1.8rem;

  }

  .custom-links li a {
    text-decoration: none;
    color: white !important;
  }

  .social-links li {
    list-style: none;
    line-height: 1.8rem;

  }

  .social-links li a {
    text-decoration: none;
    color: white !important;
    font-size: 50px;
    margin: 0px 30px;
  }

  h3 {
    font-weight: var(--fontSemiBold);
  }

  .choose-plan-main {
    display: flex;
    align-items: center;
  }

  .choose-plan-main button {
    width: 250px;
    height: 180px;
    margin: 0 15px;
    background-color: transparent;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: solid 2px var(--blackColor);
    border-radius: 10px;
  }

  .choose-plan-main button h3 {
    font-size: 25px;
    color: var(--blackColor);
    font-weight: var(--fontBold);
    margin-top: 20px;
  }

  .choose-plan-main button:hover {
    border-color: var(--orangeColor);
  }

  .popup-form-main label {
    color: var(--blackColor);
  }


  @media (min-width: 576px) {
    .navbar-expand-lg .navbar-nav .btn-primary {
      padding-right: 20px;
      padding-left: 20px;
    }
    .navbar-expand-lg .navbar-nav .btn-secondary {
      padding-right: 35px;
      padding-left: 35px;
    }
  }


  @media (max-width: 991px) {
    .nav-link {
      display: flex;
      justify-content: center;
    }
    .nav-link .btn-primary {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .nav-link .btn-secondary {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    #login-btn {
      margin-top: 10px;
    }
    .cart-item-area {
      border: 0;
    }
    .cart-items-main {
      padding-right: 0;
    }
    .cart-summary-area {
      padding-left: 0;
      margin-top: 30px;
    }
  }


  @media (max-width: 767px) {
    .breadcrumb-left {
      margin-bottom: 10px;
    }
    .category-left-menu {
      margin-bottom: 30px;
    }
    .product-detail-area {
      padding: 15px;
    }
    .product-view .product-view-image {
      flex: 0 0 100%;
      padding-right: 0;
    }
    .product-view .product-image-list {
      flex: 0 0 100%;
    }
    .product-view .product-view-image .product-time-date {
      width: 100%;
    }
    .product-view .product-image-list ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .product-view .product-image-list ul li {
      flex: 0 0 30%;
      margin: 5px;
    }
    .product-description {
      width: 100%;
    }
    .price {
      margin-top: 15px;
    }
    .product-form-main .addtocart-button {
      padding: 10px 13px;
      font-size: 13px;
    }
    .cart-main-area {
      padding: 36px 15px 70px;
    }
    .cart-items-main {
      overflow-x: auto;
    }
    .cart-item {
      width: 600px;
    }
    .plan-main-area button {
      margin-bottom: 20px;
    }
    .profile-card {
      margin-bottom: 30px;
    }
  }

  .swiper {
    padding: 3rem;
  }

  .swiper-button-prev {
    padding: 1rem;
    color: #F26600;
  }

  .swiper-button-next {
    color: #F26600;
}


  @media (max-width: 550px) {
    .promo-products-main {
      padding: 30px 60px;
    }
    .filters-right .search-main .form-control {
      width: 170px;
    }
  }

  h1.suplier-name {
    color: #F26600;
    margin-left: 10px;
    background: #FFF;
    padding: 5px;
    width: fit-content;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 800;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
  }
}
.ProductsPage .promo-bannar-area .promo-products-main .owl-item {
  padding-right: 10px;
}

.ProductsPage {
  .product-grid-main {
    .product-image img {
      // max-height: 180px;
    }

    .product-content {
      min-height: 170px;

      .product-content-right button svg {
        font-size: 30px;
      }
    }

    .product-content-left {
      width: 100%;
    }

    .product-content-sale {
      width: 100%;
    }
  }

}
