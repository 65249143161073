/********** Colors **********/
/* primary colors */

$xequemate-blue-dark: #0E214F;
$xequemate-orange: #F26601;

/* secondary colors */
$xequemate-khaki: #F3CFB5;
$xequemate-white: #F7F7F7;
$xequemate-black: #000000;

/* supporting colors */
$xequemate-gray: #EFEAEA;
$xequemate-gray-dark: #4A4A4A;
$xequemate-orange-dark: #E86506;
$xequemate-khaki-dark: #F3B588;
$xequemate-blue: #283C6F;

/* border-radius */
$radius: .5rem;

// section content max width 
$maxwidth-mobile: 29rem;
$maxwidth-tablet: 40rem;
$maxwidth-desktop: 31rem ;

// section padding 
$sectionpadding-mobile: 3rem 2rem;
$sectionpadding-desktop: 4rem;