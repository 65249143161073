.FloatingWhatsApp {
  .float {
    position: fixed;
    bottom: 40px;
    right: 40px;
    color: #25d366;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    z-index: 100;
  }

  .fa-whatsapp {
    width: 60px;
    height: 60px;

  }

  .my-float {
    margin-top: 16px;
  }
}