@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700;800;900&display=swap');

:root {
  --whiteColor: #ffffff;
  --blackColor: #000000;
  --lightGrayColor: rgba(0, 0, 0, 0.70);
  --orangeColor: #F26600;
  --borderColor: rgba(0, 0, 0, .2);
  --fontLight: 300;
  --fontRegular: 400;
  --fontMedium: 500;
  --fontSemiBold: 600;
  --fontBold: 700;
  --fontExtraBold: 800;
  --fontBlack: 900;
}

body {
  background: linear-gradient(224deg, #0F214F 0%, #081024 100%);
  color: var(--whiteColor);
  font-family: 'Roboto', sans-serif;
  font-weight: var(--fontRegular);
}


.Header {
    * h1, h2, h3, h4, h5, h6, ul, ol, p {
      margin: 0;
      padding: 0;
    }

    .navbar {
      background-color: #0F214F !important;
    }

    nav ul li a, nav a {
      color: #FFF !important;
    }

    .nav-link {
      font-size: 18px;
      font-weight: var(--fontMedium);
      text-transform: uppercase;
    }

    @media (max-width: 1200px) {
      .nav-link {
        font-size: 15px;
      }
    }

    @media (max-width: 991px) {
      .nav-link {
        font-size: 16px;
      }
    }

    nav ul li a:hover, nav a:hover {
      color: var(--orangeColor) !important;
    }

    .btn-primary {
      background-color: transparent;
      border: 2.5px solid var(--orangeColor);
      color: var(--orangeColor) !important;
      padding: 7px 25px;
      font-weight: var(--fontSemiBold);
    }

    .btn-primary:hover {
      background-color: var(--orangeColor);
      color: white !important;
      border: 2.5px solid var(--orangeColor);
    }

    .btn-secondary {
      background-color: var(--orangeColor);
      border: 2px solid var(--orangeColor);
      color: #0F214F !important;
      padding: 7px 35px;
      font-weight: var(--fontSemiBold);
    }

    .btn-secondary:hover {
      border: 2px solid var(--orangeColor);
      background-color: var(--orangeColor);
      color: white !important;
    }

    @media (min-width: 576px) {
      .navbar-expand-lg .navbar-nav .btn-primary {
        padding-right: 20px;
        padding-left: 20px;
      }

      .navbar-expand-lg .navbar-nav .btn-secondary {
        padding-right: 35px;
        padding-left: 35px;
      }
    }

    .whatsapp {
      align-items: center;
      background-color: #25d366;
      border-radius: 50px;
      bottom: 1.25rem;
      display: flex;
      height: 2.5rem;
      justify-content: center;
      position: fixed;
      right: 0.625rem;
      text-align: center;
      width: 2.5rem;
      z-index: 100;
    }

    #whatsapp {
      height: 1.6rem !important;
      width: 1.6rem !important;
    }

    @media (min-width: 768px) {
      .whatsapp {
        bottom: 1.25rem;
        height: 4.0625rem;
        right: 1.25rem;
        width: 4.0625rem;
      }
      #whatsapp {
        height: 2rem !important;
        width: 2rem !important;
      }
    }

    .hero-main {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 120vh;
      background-size: cover;
      background-position: center center;
      background-image: url('../../../public/assets/images/hero-img.png');
    }

    .hero_title {
      align-items: center;
      display: flex;
      margin-top: 138px;
      width: 100%;
      font-weight: 700;
      color: #fff;
      font-size: 5rem;
      line-height: 85px;
      text-transform: uppercase;
    }

    @media (max-width: 769px) {
      .hero_title {
        font-size: 3.5rem;
        line-height: 65px;
      }

      .btn-secondary-main {
        font-size: 1.0rem !important;
      }
    }

    @media (max-width: 487px) {
      .hero_title {
        font-size: 2.5rem;
        line-height: 45px;
      }

      .btn-secondary-main {
        font-size: 0.8rem !important;
      }

      .cadastro-btn {
        margin-top: 40px !important;
        padding: 10px 45px !important;
      }
    }

    @media (max-width: 576px) {
      .hero-main {
        height: 70vh !important;
      }
    }

    @media (max-width: 354px) {
      .hero-main {
        height: 60vh !important;
      }

      .hero_title {
        font-size: 2.1rem;
        line-height: 35px;
      }

      .btn-secondary-main {
        font-size: 0.8rem !important;
      }

      .cadastro-btn {
        margin-top: 40px !important;
        padding: 10px 45px !important;
      }
    }

    @media (max-width: 302px) {
      .hero-main {
        height: 50vh !important;
      }

      .hero_title {
        font-size: 1.7rem;
        line-height: 25px;
      }

      .btn-secondary-main {
        font-size: 0.8rem !important;
      }

      .cadastro-btn {
        margin-top: 30px !important;
        padding: 10px 35px !important;
      }
    }

    .btn-secondary-main {
      background-color: var(--orangeColor);
      border: 2px solid var(--orangeColor);
      color: #0F214F !important;
      font-weight: var(--fontBold);
      font-size: 1.3rem;
      text-transform: uppercase;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.47);
    }

    .btn-secondary-main:hover {
      border: 2px solid var(--orangeColor);
      background-color: var(--orangeColor);
      color: white !important;
    }

    .cadastro-btn {
      margin-top: 80px;
      padding: 10px 55px;
    }

    .learn-section {
      align-items: center;
      background-color: #f3cfb5;
      display: flex;
      background-size: auto 100%;
    }

    @media (min-width: 1280px) {
      .learn-section {
        background-position: 100%;
        background-image: url('../../../public/assets/images/mask-group.png');
        background-repeat: no-repeat;
        flex-direction: row;
        justify-content: space-around;
        background-size: auto 100%;
      }
    }

    .learn-title {
      margin-top: 60px;
      margin-bottom: 50px;
      font-weight: var(--fontBold);
      font-size: 4rem;
      color: #000;
    }

    @media (max-width: 1400px) {
      .learn-title {
        font-size: 3.5rem;
      }
    }

    @media (max-width: 1200px) {
      .learn-title {
        margin-top: 60px;
        margin-bottom: 50px;
        font-size: 3rem;
      }
    }

    @media (max-width: 992px) {
      .learn-title {
        margin-top: 50px;
        margin-bottom: 40px;
        font-size: 2rem;
      }

      .learn-p {
        font-size: 0.9rem !important;
        width: 100% !important;
      }

      .compre-btn {
        margin-top: 40px;
        margin-bottom: 80px;
        padding: 10px 35px;
        font-size: 1rem !important;
      }
    }

    @media (max-width: 304px) {
      .learn-title {
        margin-top: 50px;
        margin-bottom: 40px;
        font-size: 1.7rem;
      }
    }

    .learn-title-alt {
      color: var(--orangeColor);
    }

    .learn-p {
      color: #000;
      font-size: 1rem;
      font-weight: var(--fontSemiBold);
      width: 95%;
    }

    .compre-btn {
      margin-top: 40px;
      margin-bottom: 80px;
      padding: 10px 35px;
    }

    #offer-title {
      font-size: 50px;
    }

    .owl-carousel {
      position: relative;
      padding: 0 50px;
      margin-top: 50px;
      margin-bottom: 80px;
    }

    .carousel-item {
      display: flex;
      justify-content: center; /* Center the content horizontally */
      align-items: flex-start; /* Align the content to the top */
      padding: 70px 5px; /* Add some padding for spacing */
    }

    .product-card {
      padding: 0px;
      align-items: center;
      background: #f3cfb5;
      border-radius: 2%;
      width: 340px;
      transition: all 0.5s;
    }

    @media (max-width: 1000px) {
      .product-card {
        width: 350px !important;
      }
    }

    @media (max-width: 767px) {
      #offer-title {
        font-size: 40px;
      }

      .owl-carousel .owl-nav button {
        height: 610px !important;
        font-size: 2rem !important;
      }
    }

    @media (max-width: 521px) {
      .product-card {
        width: 280px !important;
      }

      .owl-carousel {
        margin-bottom: 40px;
      }

      .owl-carousel .owl-nav button {
        height: 640px !important;
        font-size: 2rem !important;
      }
    }

    @media (max-width: 430px) {
      .product-card {
        width: 250px !important;
      }

      .owl-carousel {
        margin-top: 20px;
        margin-bottom: 10px;
      }

      .product-card-title a {
        font-size: x-large !important;
      }

      .product-card-quantity {
        font-size: large !important;
      }

      .owl-carousel .owl-nav button {
        height: 640px !important;
        width: 34px !important;
        font-size: 2rem !important;
      }
    }

    @media (max-width: 385px) {
      #offer-title {
        font-size: 25px;
      }

      .carousel-item {
        padding: 20px 5px; /* Add some padding for spacing */
      }

      .product-card {
        width: 200px !important;
      }

      .product-image {
        height: 170px !important;
      }

      .owl-carousel {
        margin-top: 20px;
        margin-bottom: 10px;
      }

      .product-card-title a {
        font-size: large !important;
      }

      .product-card-quantity {
        font-size: medium !important;
      }

      .owl-carousel .owl-nav button {
        height: 440px !important;
        width: 34px !important;
      }
    }

    .product-image {
      align-items: center;
      width: 100%;
      height: 270px;
      border-top-left-radius: 2%;
      border-top-right-radius: 2%;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.47);
    }

    .product-card-content {
      padding-top: 40px;
      padding-bottom: 20px;
      padding-left: 30px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .product-card-title a {
      color: #000;
      font-weight: var(--fontBold);
      font-size: xx-large;
      text-decoration: none;
    }

    .product-card-quantity {
      color: var(--orangeColor);
      font-weight: var(--fontBold);
      font-size: x-large;
    }

    .product-card-button {
      display: flex;
      justify-content: center;
    }

    .card-btn {
      margin-top: 10px;
      margin-bottom: 40px;
      padding: 10px 35px;
    }

    .middle-item {
      /*width: 100%;*/
      /*height: 105% !important;*/
      border: 5px solid var(--orangeColor); /* Orange border */
      /*margin: -7% -35%; !* Adjust margin to keep spacing consistent *!*/
      transition: all 0.3s;
      transform: scale(1.1);
    }

    .supplier-section {
      background-color: #f3cfb5 !important;
      display: flex;
      background-size: auto 100%;

    }

    @media (min-width: 1280px) {
      .supplier-section {
        background-position: center left;
        background-image: url('../../../public/assets/images/supply-img.png');
        background-repeat: no-repeat;
        flex-direction: row;
        justify-content: space-around;
        display: flex;
        background-size: auto 100%;
      }
    }

    @media (max-width: 1279px) {
      .col-lg-single {
        flex: 0 0 100%; /* Force columns to be full width */
        max-width: 100%; /* Ensure content doesn't overflow */
      }
    }

    .supplier-text {
      margin-top: 70px;
      width: 85%;
    }

    .supplier-title {
      color: black;
      font-size: 50px;
      font-weight: var(--fontBold);
    }

    .supplier-p {
      color: black;
      font-size: 25px;
      font-weight: var(--fontMedium);
    }

    @media (max-width: 1280px) {
      .supplier-text {
        margin-top: 50px;
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
      }

      .supplier-p {
        margin-top: 20px;
        width: 80%;
      }
    }

    @media (max-width: 720px) {
      .supplier-text {
        margin-top: 70px;
        width: 85%;
      }

      .supplier-title {
        font-size: 40px;
      }

      .supplier-p {
        color: black;
        font-size: 20px;
        font-weight: var(--fontMedium);
      }
    }

    @media (max-width: 567px) {
      .supplier-text {
        width: 100%;
      }

      .supplier-p {
        font-size: 20px;
      }
    }

    @media (max-width: 440px) {
      .supplier-title {
        font-size: 30px;
      }

      .supplier-p {
        font-size: 17px;
        width: 100%;

      }
    }

    .subscription-section {
      background-color: #0F214F !important;
      display: flex;

    }

    @media (min-width: 1280px) {
      .subscription-section {
        background-image: url('../../../public/assets/images/vector4.png'), url('../../../public/assets/images/vector5.png'), /* Top images */
        url('../../../public/assets/images/vector4.png'), url('../../../public/assets/images/vector5.png'); /* Bottom images */
        background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
        background-position: left top 130px, right top 130px, left bottom 100px, right bottom 100px;
        background-size: auto, auto, auto, auto;
      }
    }

    .subscription-title {
      font-size: 50px;
    }

    .subscription-description {
      font-size: 27px;
      line-height: 2rem;
    }

    .subscription-video-blur {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 60px 260px;
      -webkit-backdrop-filter: blur(4.5px);
      backdrop-filter: blur(4.5px);
      background: linear-gradient(180deg, hsla(0, 0%, 100%, .3) 30%, hsla(0, 0%, 100%, 0));
      border: 2px solid hsla(0, 0%, 100%, .3);
      border-radius: 0.3125rem;
      height: 33rem;
      margin-bottom: 10rem;
    }

    @media (max-width: 1100px) {
      .subscription-video-blur {
        padding: 60px 200px;
        height: 33rem;
        margin-bottom: 10rem;
      }
    }

    @media (max-width: 940px) {
      .subscription-video-blur {
        padding: 60px 150px;
        height: 33rem;
        margin-bottom: 10rem;
      }
    }

    @media (max-width: 810px) {
      .subscription-video-blur {
        padding: 60px 100px;
        height: 33rem;
        margin-bottom: 10rem;
      }
    }

    @media (max-width: 767px) {
      .subscription-plans {
        margin-bottom: 5rem !important;
      }
    }

    @media (max-width: 710px) {
      .subscription-video-blur {
        padding: 40px 50px;
        height: 30rem;
        margin-bottom: 10rem;
      }

      .subscription-title {
        font-size: 40px;
      }

      .subscription-description {
        font-size: 21px;
        line-height: 1.5rem;
      }
    }

    @media (max-width: 495px) {
      .subscription-video-blur {
        padding: 40px 50px;
        height: 30rem;
        margin-bottom: 6rem;
      }

      .subscription-title {
        font-size: 34px;
      }

      .subscription-description {
        font-size: 19px;
        line-height: 1.5rem;
      }
    }

    @media (max-width: 370px) {
      .subscription-video-blur {
        padding: 20px 40px;
        height: 25rem;
        margin-bottom: 6rem;
      }

      .subscription-title {
        font-size: 28px;
      }

      .subscription-description {
        font-size: 16px;
        line-height: 1.2rem;
      }

      .subscription-video-title {
        font-size: 18px;
      }
    }

    @media (max-width: 303px) {
      .subscription-video-blur {
        padding: 20px 40px;
        height: 20rem;
        margin-bottom: 4rem;
      }

      .subscription-title {
        font-size: 24px;
      }

      .subscription-description {
        font-size: 14px;
        line-height: 1.2rem;
      }

      .subscription-video-title {
        font-size: 16px;
      }

      .subscription-video {
        height: 200px;
      }
    }

    .subscription-video-title {
      text-transform: uppercase;
    }

    .subscription-plans {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 13rem;
    }

    .subscription-plan-card {
      align-items: center;
      background: linear-gradient(180deg, hsla(0, 0%, 100%, .3), hsla(0, 0%, 100%, 0));
      border: 2px solid hsla(0, 0%, 100%, .3);
      border-radius: 0.3395rem;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      max-width: 46.875rem;
      transition: all 0.5s;
      width: 80%;
      position: relative;
    }

    .subscription-plan-card:hover {
      transform: scale(1.05);
    }

    .subscription-plan-card:hover::before {
      content: "";
      position: absolute;
      top: -17px;
      left: -17px;
      right: -17px;
      bottom: -17px;
      border: 5px solid var(--orangeColor);
      border-radius: 0.3395rem;
    }

    // .subscription-plan-card:hover::before {
    //   content: "";
    //   position: absolute;
    //   top: -17px;
    //   left: -17px;
    //   right: -17px;
    //   bottom: -17px;
    //   border: 5px solid var(--orangeColor);
    //   border-radius: 0.3395rem;
    // }

    .subscription-plan-title,
    .subscription-plan-p,
    .subscription-plan-price,
    .subscription-plan-button,
    .subscription-plan-card svg {
      margin: 1rem;
      font-weight: bold;
    }

    .faq-section {
      background-color: #f3cfb5 !important;
      display: flex;
    }

    @media (min-width: 1280px) {
      .faq-section {
        background-position: right bottom 20px;
        background-image: url('../../../public/assets/images/vector1.png');
        background-repeat: no-repeat;
        background-size: auto;

      }
    }

    .faq-container {
      margin-bottom: 6rem;
    }

    .btn-faq {
      background-color: transparent;
      border: 3.5px solid #565656;
      border-radius: 0;
      color: black !important;
      padding: 13px 15px;
      font-size: 20px;
      font-weight: var(--fontSemiBold);
      transition: all 0.5s;
    }

    @media (max-width: 576px) {
      .faq-btn-container {
        gap: 1rem;
        justify-content: center !important;
      }

      .btn-faq {
        padding: 10px 12px;
        font-size: 16px;
      }
    }

    .btn-faq:hover {
      border: 3.5px solid #565656;
      border-radius: 0;
      transform: scale(1.05);
    }

    .faq-btn-selected {
      border-color: #f26601;
      color: #f26601 !important;
    }

    .faq-heading {
      color: black;
      font-size: 19px;
      font-weight: bold;
    }

    .faq-answer {
      color: black;
      font-size: 18px;
      font-weight: var(--fontMedium);
      width: 90%;
    }


    footer {
      background-color: #0F214F !important;
    }

    .footer-container {
      padding-top: 80px;
      padding-bottom: 20px;
    }

    .custom-links li {
      list-style: none;
      line-height: 1.8rem;

    }

    .custom-links li a {
      text-decoration: none;
      color: white !important;
    }

    .social-links li {
      list-style: none;
      line-height: 1.8rem;

    }

    .social-links li a {
      text-decoration: none;
      color: white !important;
      font-size: 50px;
      margin: 0px 30px;
    }

    h3 {
      font-weight: var(--fontSemiBold);
    }

    @media (max-width: 290px) {
      #footeremail {
        font-size: smaller;
      }
    }


    @media (max-width: 991px) {
      .nav-link {
        display: flex;
        justify-content: center;
      }

      .nav-link .btn-primary {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .nav-link .btn-secondary {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      #login-btn {
        margin-top: 10px;
      }
    }


    .owl-carousel .owl-nav button {
      width: 104px;
      height: 610px;
      font-size: 3rem !important;
      font-weight: var(--fontExtraBold);
      text-align: center;
      color: var(--orangeColor) !important;
    }

    button.owl-prev {

      position: absolute;
      top: 20px;
      left: 0;

    }

    button.owl-next {

      position: absolute;
      top: 20px;
      right: 0;
    }
}